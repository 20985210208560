@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap');

body, html, #root {
  height: 100%;
}

a, div, p, span {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.app-wrapper .app-box {
  border-bottom: solid 1px #ddd;
  border-top: solid 1px #ddd;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: 75px 20px;
  justify-content: center;
  min-height: calc(100vh - 554px);
}
@media screen and (max-width: 450px) {
  .app-wrapper .app-box {
    padding: 25px 20px;
  }
}

.error-notice, 
.red {
  color: red;
}
